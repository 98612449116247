import React from "react";
import styles from "./BoxComponent.module.scss";
const BoxComponent = (props) => {
  const { title, value, unit } = props;
  return (
    <div className={styles.BoxComponentWrapper}>
      <div className={styles.mainTitle}>{title}</div>
      <div className={styles.value}>{value}</div>
      <div className={styles.unit}>{unit}</div>
    </div>
  );
};

export default BoxComponent;
