import SyngLogo from "../assets/img/logo1.png";
import AdvaLogo from "../assets/img/logo2.png";
import IcrLogo from "../assets/img/logo3.png";
import PrasadLogo from "../assets/img/logo4.png";
import NamLogo from "../assets/img/logo5.png";
import FarmLinkLogo from "../assets/img/logo6.png";
import HyLogo from "../assets/img/logo7.png";
import HMLogo from "../assets/img/logo8.png";
import SavaLogo from "../assets/img/logo9.png";
import SeedLogo from "../assets/img/logo10.png";
import HarvestLogo from "../assets/img/logo11.png";
// import SuperLogo from "../assets/img/logo12.png";
import DeltaLogo from "../assets/img/logo13.png";
import SeaLogo from "../assets/img/logo14.png";
import OurImg1 from "../assets/img/our1.png";
import OurImg2 from "../assets/img/our2.png";
import OurImg3 from "../assets/img/our3.png";
import Insta from "../assets/img/insta.png";
import FB from "../assets/img/facebook.png";
import LinkedIn from "../assets/img/linkedin.png";
import EastWestLogo from "../assets/img/eastWest.jpeg";
import UPLLogo from "../assets/img/upl.png";
import NathLogo from "../assets/img/nath.jpeg";

import HomeSliderImage1 from "../assets/img/slider1.jpeg";
import HomeSliderImage2 from "../assets/img/slider2.jpeg";
import HomeSliderImage3 from "../assets/img/slider3.jpeg";
import HomeSliderImage4 from "../assets/img/slider4.jpeg";

export const boxSectionData = [
    {
        title: "Activities Grounded",
        value: 30,
        unit: "Lac",
    },
    {
        title: "Villages Mapped",
        value: 2,
        unit: "Lac",
    },
    {
        title: "Demos Monitored",
        value: 32,
        unit: "K",
    },
    {
        title: "Dealers Mapped",
        value: 25,
        unit: "K",
    },
];
export const solutionListData = [
    "Farmer Profiling",
    "Season Planning",
    "Target Setting",
    "Market Survey",
    "Field Attendance",
    "Digital Marketing",
    "Dealer Classification",
    "Activity Monitoring",
    "Inventory Management",
    "Village Mapping",
    "Demo Management",
    "Performance Assessment",
];

export const clientLogo = [
    SyngLogo,
    AdvaLogo,
    IcrLogo,
    PrasadLogo,
    NamLogo,
    FarmLinkLogo,
    HyLogo,
    HMLogo,
    SavaLogo,
    SeedLogo,
    HarvestLogo,
    // SuperLogo,
    DeltaLogo,
    SeaLogo,
    EastWestLogo,
    NathLogo,
    UPLLogo,
];

export const headerSliderData = [
    {
        title: "MEASURE ROI ON",
        mainTitle: "Field Demonstrations",
        description: "",
        image: HomeSliderImage2,
    },
    {
        title: "MARKET INSIGHTS FOR",
        mainTitle: "In-Season Planning",
        description: "",
        image: HomeSliderImage4,
    },
    {
        title: "REAL-TIME MONITORING FOR",
        mainTitle: "Seed Production",
        description: "",
        image: HomeSliderImage3,
    },
    {
        title: "PLANNING AND MONITORING TOOL FOR",
        mainTitle: "Demand Generation",
        description: "",
        image: HomeSliderImage1,
    },
];

export const ourWorkSliderData = [OurImg1, OurImg2, OurImg3];
export const socialMediaIcons = [{
  url: "https://www.instagram.com/kisangates/",
  img: Insta
}, {
  url: "https://www.facebook.com/kisangates2020",
  img: FB
}, {
  url: "https://www.linkedin.com/company/kisangates-agro-informatics-pvt.-ltd./",
  img: LinkedIn
}];
