import styles from "./App.module.scss";
import HomePage from "./pages/HomePage/HomePage";
// import { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import NavbarComponent from "./components/NavbarComponent/Navbar";
import Footer from "./components/Footer/Footer";
import AboutPage from "./pages/AboutPage/AboutPage";
import TermsPage from "./pages/TermsPage/Terms";
import PolicyPage from "./pages/PolicyPage/Policy";
function App() {
  // const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   i18n.changeLanguage("en");
  // }, []);
  return (
    <div className={styles.App}>
      <div className={styles.mainWrapper}>
        <Container fluid className={styles.customContainer}>
          <NavbarComponent />

          <Routes>
            <Route path="/*" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/policy" element={<PolicyPage />} />
          </Routes>
          <Footer />
        </Container>
      </div>
    </div>
  );
}

export default App;
