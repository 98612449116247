import React from "react";
import { Container } from "react-bootstrap";
import SliderComponent from "../../components/SliderComponent/SliderComponent";
import { headerSliderData } from "../../config/AppConfig";
import OurClient from "./Components/OurClient/OurClient";
import OurWorkSection from "./Components/OurWork/OurWorkSection";
import SolutionSection from "./Components/SolutionSection/SolutionSection";
import StatisticSection from "./Components/Static/StatisticSection";
import styles from "./HomePage.module.scss";
const HomePage = () => {
  return (
    <div className={styles.homePageWrapper}>
      <div className={styles.headerSection}>
        <SliderComponent>
          {headerSliderData.map((data, key) => (
            <div
              classsName={styles.SliderContentWraper}
              key={key + Math.random()}
            >
              <div
                className={styles.sliderContentWrapper}
                style={{ backgroundImage: "url(" + data.image + ")" }}
              >
                <div className={styles.sliderTitle}>{data.title}</div>
                <div className={styles.sliderMainTitle}>{data.mainTitle}</div>
                <div className={styles.sliderDescription}>
                  {data.description}
                </div>
              </div>
            </div>
          ))}
        </SliderComponent>
      </div>
      <Container className={styles.contentSection}>
        <StatisticSection />
      </Container>
      <SolutionSection />
      <OurClient />
      <OurWorkSection />
    </div>
  );
};

export default HomePage;
