import React from "react";
import BoxComponent from "../../../../components/BoxComponet/BoxComponent";
import { boxSectionData } from "../../../../config/AppConfig";
import PurposeDataSection from "./PurposeDataSection.jsx";
import styles from "./StatisticSection.module.scss";

const StatisticSection = () => {
  return (
    <div className={styles.statisticSectionWrapper}>
      <div className={styles.boxSection}>
        {boxSectionData.map((data, index) => (
          <BoxComponent
            key={Math.random(index)}
            title={data.title}
            unit={data.unit}
            value={data.value}
          />
        ))}
      </div>
      <PurposeDataSection />
    </div>
  );
};

export default StatisticSection;
