import React from "react";
import { Container, Button } from "react-bootstrap";
import { solutionListData } from "../../../../config/AppConfig";
import styles from "./solutionSection.module.scss";

const SolutionSection = () => {
  return (
    <div className={styles.solutionSectionWrapper}>
      <Container className={styles.contentSection}>
        <div className={styles.contenWrapper}>
          <div className={styles.title}>
            Our Solutions
            <div className={styles.subtitle}>What we have</div>
          </div>
          <div className={styles.listSection}>
            {solutionListData.map((data, key) => (
              <div className={styles.text} key={Math.random(key)}>
                {data}
              </div>
            ))}
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Button
              variant="outline-light"
              href="https://f7b656a3.sibforms.com/serve/MUIEAH9u6TlTTbKecI6TDcDUlwVN4VCuKWezwMbWV7LWNt7IvmM3xjrlZwjyibHchSnfeCJCSs5g3pNSAvCQQEAzSo_KpicW7QykFbnASeGC6nU9p1xc1iw7K6GwEvF3k-Mdva3xk-IL6KHElZT3lh1wdj4dxe7bRORrZpS_6WrI-kruKLEtKmf1YfcAERgTpNrm2HyrakzjwZlw"
              size="lg">Request for Demo</Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SolutionSection;
