import React from "react";

import styles from "./purposeDataSection.module.scss";
import PurposeImg1 from "../../../../assets/img/purpose1.png";
import PurposeImg2 from "../../../../assets/img/purpose2.png";
import PurposeImg3 from "../../../../assets/img/purpose3.png";
import PurposeImg4 from "../../../../assets/img/purpose4.png";

const PurposeDataSection = () => {
  return (
    <div className={styles.purposeSetion}>
      <div className={styles.imageSection}>
        <div className={styles.leftColumnImg}>
          <img src={PurposeImg1} />
          <img src={PurposeImg3} />
        </div>
        <div className={styles.rightColumnImg}>
          <img src={PurposeImg2} />
          <img src={PurposeImg4} />
        </div>
      </div>
      <div className={styles.contentSection}>
        <div className={styles.title}>Our Purpose</div>
        <div className={styles.description}>
          Software will feed the world.
          <br />
          Agtech revolution is Real.{" "}
          <div className={styles.subText}>
            Agricultural Extension in India is Broken.
            <br />
            <div className={styles.subTextDesc}>
              Pandoforce mission is to build on-demand extension channels which
              can help companies increase their revenues and reduce the
              cost-to-serve their hyperlocal markets.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurposeDataSection;
