import React from "react";
import Title from "../../../../components/TitleComponent/Title";

import styles from "./ourClient.module.scss";
import DotImg from "../../../../assets/img/dot.png";
import { clientLogo } from "../../../../config/AppConfig";

const OurClient = () => {
  return (
    <div className={styles.ourClientWrapper}>
      <div className={styles.titleSection}>
        <Title
          title="Our Clients"
          subTitle="Whom we served"
          customClass="clientTitle"
        />
        <div className={styles.dotSection}>
          <img src={DotImg} alt={"dotDesign"} />
        </div>
      </div>
      <div className={styles.logoSection}>
        {clientLogo.map((logo, key) => (
          <div className={styles.logoWrapper} key={key}>
            <img src={logo} alt={"logo"} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClient;
