import React from "react";
import { Container } from "react-bootstrap";
import SliderComponent from "../../../../components/SliderComponent/SliderComponent";
import Title from "../../../../components/TitleComponent/Title";
import { ourWorkSliderData } from "../../../../config/AppConfig";

import styles from "./ourWorkSection.module.scss";

const OurWorkSection = () => {
  return (
    <div className={styles.ourWorkSectionWrapper}>
      <Container className={styles.contentSection}>
        <div className={styles.titleSection}>
          <Title
            title="Some of our works"
            subTitle="Simple, yet powerful"
            customClass="workSectionTitle"
          />
        </div>
        <div className={styles.sliderSectionWrapper}>
          <SliderComponent
            customSettings={{
              dots: true,
              autoplay: true,
              slidesToShow: 2,
              infinite: true,
            }}
          >
            {ourWorkSliderData.map((image, key) => (
              <div className={styles.sliderSection} key={key}>
                <img
                  src={image}
                  alt="sliderImg"
                  className={styles.sliderImage}
                />
              </div>
            ))}
          </SliderComponent>
        </div>
      </Container>
    </div>
  );
};

export default OurWorkSection;
