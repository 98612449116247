import React from "react";
import styles from "./title.module.scss";
const Title = (props) => {
  const { title, subTitle, customClass } = props;
  return (
    <div className={`${styles.titleWrapper} ${styles[customClass]}`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  );
};

export default Title;
