import React from "react";
import { Container } from "react-bootstrap";

import styles from "./aboutPage.module.scss";
const AboutPage = () => {
  return (
    <div className={styles.aboutPageWrapper}>
      <Container className={styles.contentSection}>
        <h1>About Page</h1>
      </Container>
    </div>
  );
};

export default AboutPage;
