import React from "react";
import { Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { socialMediaIcons } from "../../config/AppConfig";
import styles from "./footerstyle.module.scss";

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.contenWrapper}>
        <nav className={`navbar  navbar-light bg-light ${styles.footerNav}`}>
          <Container className={styles.customContainer}>
            <div>
              <a className="navbar-brand" href="https://pandoforce.com/">
                Copyright © 2023 All Rights Reserved.
              </a>
            </div>
            <div>
              <a
                // to={"/terms"}
                href="https://pandoforce.com/index.php/site/terms"
                className={`navbar-brand ${styles.centerLink}`}
              >
                Terms
              </a>
              <span className={styles.verticalSepration}>|</span>
              <a
                className={`navbar-brand ${styles.centerLink}`}
                // to={"/policy"}
                href="https://pandoforce.com/index.php/site/policies"
              >
                Policies
              </a>
              <span className={styles.verticalSepration}>|</span>
              <a
                className={`navbar-brand ${styles.centerLink}`}
                href="https://pandoforce.com/index.php/site/contact"
              >
                Contact
              </a>
            </div>
            <div>
              {/*{socialMediaIcons.map((item) => (*/}
              {/*  <a className="navbar-brand" href={item.url} key={item.url}>*/}
              {/*    <img src={item.img} alt={"social media icon"} />*/}
              {/*  </a>*/}
              {/*))}*/}
            </div>
          </Container>
        </nav>
      </div>
    </div>
  );
};

export default Footer;
