import React from "react";
import { Container } from "react-bootstrap";

import styles from "./terms.module.scss";
const TermsPage = () => {
  return (
    <div className={styles.termsPageWrapper}>
      <Container className={styles.contentSection}>
        <h1>Terms Page</h1>
        <div></div>
      </Container>
    </div>
  );
};

export default TermsPage;
