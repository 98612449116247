import React from "react";
import styles from "./Navbar.module.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import PandoLogo from "../../assets/img/PandoLogo.png";
import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
const NavbarComponent = () => {
  return (
    <div className={styles.NavBarWrapper}>
      <Container className={styles.customContainer}>
        <Navbar bg="dark" expand="lg">
          <Container className={styles.innerContainer}>
            <a href={"https://pandoforce.com/"} className={`navbar-brand ${styles.brandLogo}`}>
              {" "}
              <img src={PandoLogo} alt="pando-logo" />
            </a>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {/*<Link className="nav-link" to={"/"}>*/}
                {/*  Home*/}
                {/*</Link>*/}
                {/*<a className="nav-link" href="#">*/}
                {/*  About*/}
                {/*</a>*/}
              </Nav>
              <Nav>
                <Nav.Link href="https://pandoforce.com/index.php/site/login">
                  <Button
                    variant="primary"
                    size="lg"
                    className={styles.loginButton}
                  >
                    Login
                  </Button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </div>
  );
};

export default NavbarComponent;
