import React from "react";
import { Container } from "react-bootstrap";

import styles from "./policy.module.scss";
const PolicyPage = () => {
  return (
    <div className={styles.policyPageWrapper}>
      <Container className={styles.contentSection}>
        <h1>Policy Page</h1>
      </Container>
    </div>
  );
};

export default PolicyPage;
