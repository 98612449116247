import React from "react";
import styles from "./sliderComponentStyle.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderComponent = (props) => {
  const { customSettings } = props;
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    ...{ ...customSettings },
  };

  return (
    <div className={styles.sliderComponentWrapper}>
      <Slider {...settings}>{props.children}</Slider>
    </div>
  );
};

export default SliderComponent;
